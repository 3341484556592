.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #f3f3f3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(57, 57, 57);
}

.p{
  font-size: 1em;
  color: rgb(57, 57, 57);
}

.notify {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f8f8f8;
  border-left: 5px solid #4CAF50; /* Green for success */
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  z-index: 1000;
}

.notify--success {
  border-color: #4CAF50; /* Green for success */
}

.notify--error {
  border-color: #f44336; /* Red for error */
}

.notify__content {
  display: flex;
  align-items: center;
}

.notify__icon {
  /* styles for the icon */
  margin-right: 10px;
}

.notify__title {
  /* styles for the title/message */
}

.skeleton {
  background-color: #eee;
  padding: 10px;
  border-radius: 5px;
  color: #999;
  font-size: small;
}

@media (min-width: 768px) {
  .mobile-only {
      display: none;
  }
}
